import Interpolate from '@engined/client/components/Interpolate.js';
import { useLocale } from '@engined/client/contexts/LocaleContext.js';
import { LoaderFunctionArgs, MetaFunctionArgs } from '@engined/core/interfaces.js';
import { Box, Link, Stack, Typography } from '@mui/material';
import { NOT_FOUND_DESCRIPTION, NOT_FOUND_GOTO, NOT_FOUND_TITLE } from '@asaprint/asap/locales/client.js';
import { DASHBOARD_ROUTE } from '@asaprint/asap/routes.js';
import Copyright from '@engined/client/components/Copyright.js';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

interface OwnProps {}

type Props = OwnProps;

const NotFound: React.FunctionComponent<Props> = () => {
  const { t } = useLocale();

  return (
    <>
      <Stack sx={{ mt: 8 }} alignItems="center">
        <Typography variant="h1" component="h1">
          404!
        </Typography>
        <Typography variant="h3" component="h3">
          <Interpolate resource={NOT_FOUND_TITLE} />
        </Typography>
        <Typography variant="body1" component="p" textAlign="center" sx={{ mt: 2 }}>
          <Interpolate resource={NOT_FOUND_DESCRIPTION} />
          <br />
          <Interpolate
            resource={NOT_FOUND_GOTO}
            components={[<Link key="link" component={RouterLink} to={DASHBOARD_ROUTE} />]}
          />
        </Typography>
      </Stack>

      <Box marginTop={8}>
        <Copyright />
      </Box>
    </>
  );
};

NotFound.displayName = 'NotFound';

export default NotFound;

export const loader = async ({ context: { req, apollo } }: LoaderFunctionArgs) => {
  throw new Response('', { status: 404 });
};

export const handle = {
  meta: ({ locale: { t } }: MetaFunctionArgs) => ({
    title: t(NOT_FOUND_TITLE),
  }),
};
